(function () {
    "use strict";
    angular.module("vatUtils")

.constant("ENV", "prod")

.constant("VERSION", "1.0.8")

;
})();
