(function () {
    'use strict';

    angular
        .module('vatUtils')
        .factory('RequestLog', RequestLog)
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$state', 'pagingParams', 'DateRangePickerConfig', 'RequestLog', 'FilesSimpleUploadService'];

    function HomeController($scope, $state, pagingParams, DateRangePickerConfig, RequestLog, FilesSimpleUploadService) {
        var vm = this;
        vm.tableFilters = pagingParams;

        var options = {
            eventHandlers: {
                'cancel.daterangepicker': function (ev, picker) {
                    vm.tableFilters.period = {};
                    vm.search();
                },
                'apply.daterangepicker': function (ev, picker) {
                    vm.search();
                }
            }
        };
        vm.daterangepicker_options = DateRangePickerConfig.get(options);

        vm.checkFile = function () {
            FilesSimpleUploadService.open().result
                .then(function () {
                    reloadData();
                });
        };

        reloadData();

        function reloadData() {
            RequestLog.query(transformParams(vm.tableFilters), onSuccess, onError);

            function onSuccess(response, headers) {
                vm.items = response;
                vm.itemsCount = headers('X-Total-Count');
            }

            function onError() {
                vm.items = [];
                vm.itemsCount = 0;
            }

            function transformParams(tableFilters) {
                var params = {};
                angular.copy(tableFilters, params);
                params.page -= 1;
                delete params.period;
                return params;
            }
        }

        function transition() {
            delete vm.items;
            delete vm.itemsCount;
            if (vm.tableFilters.period.startDate && vm.tableFilters.period.endDate) {
                vm.tableFilters.start = moment(vm.tableFilters.period.startDate).toISOString();
                vm.tableFilters.end = moment(vm.tableFilters.period.endDate).toISOString();
            } else {
                delete vm.tableFilters.start;
                delete vm.tableFilters.end;
            }
            // vm.tableFilters.sort = vm.tableFilters.predicate + ',' + (vm.tableFilters.ascending ? 'asc' : 'desc');
            $state.transitionTo($state.$current, vm.tableFilters, {notify: false});
        }

        $scope.$on('requestLog:updated', function () {
            reloadData();
        });

        vm.search = function () {
            vm.tableFilters.page = 1;
            transition();
            reloadData();
        };

        vm.paginate = function () {
            transition();
            reloadData();
        };
    }

    RequestLog.$inject = ['$resource'];

    function RequestLog($resource) {
        return $resource('api/request-log/:id', {id: '@id'}, {
            'query': {method: 'GET', isArray: true},
            'update': {method: 'PUT'},
            'process': {method: 'POST', url: 'api/request-log/process'}
        });
    }
})();
