(function() {
    'use strict';

    var tableFiltersSwitch = {
        template: '<div class="form-group input-group-sm visible-xs filter-radio-button">\n' +
        '            <button type="button" class="btn btn-default btn-xs" ng-click="$ctrl.showFilters = !$ctrl.showFilters">\n' +
        '                <i class="fas fa-filter"></i>\n' +
        '            </button>\n' +
        '        </div>',
        bindings: {
            showFilters: '='
        }
    };

    angular
        .module('vatUtils')
        .component('tableFiltersSwitch', tableFiltersSwitch);
})();
