(function() {
    'use strict';

    angular
        .module('vatUtils')
        .factory('authExpiredInterceptor', authExpiredInterceptor);


    authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector', '$document', '$sessionStorage'];

    function authExpiredInterceptor($rootScope, $q, $injector, $document, $sessionStorage) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError(response) {
            // If we have an unauthorized request we redirect to the login page
            // Don't do this check on the account API to avoid infinite loop
            // if (response.status === 401 && angular.isDefined(response.data.path) && response.data.path.indexOf('/api/account') === -1) {
            //     // var Auth = $injector.get('Auth');
            //     // Auth.logout();
            //     var to = $rootScope.toState;
            //     var params = $rootScope.toStateParams;
            //     if (to.name !== 'accessdenied') {
            //         $sessionStorage.previousStateName = to.name;
            //         $sessionStorage.previousStateParams = params;
            //     }
            //     else{
            //         $sessionStorage.previousStateName = 'home';
            //         $sessionStorage.previousStateParams = {};
            //     }
            //     var LoginService = $injector.get('LoginService');
            //     // LoginService.open();
            // }
            // else if (response.status === 403 && response.config.method !== 'GET' && getCSRF() === '') {
            //     // If the CSRF token expired, then try to get a new CSRF token and retry the old request
            //     var $http = $injector.get('$http');
            //     return $http.get('/').finally(function() { return afterCSRFRenewed(response); });
            // }

            if(response.status === 401){
                var to = $rootScope.toState;
                var params = $rootScope.toStateParams;
                if (to.name !== 'accessdenied' || to.name !== 'login') {
                    $sessionStorage.previousStateName = to.name;
                    $sessionStorage.previousStateParams = params;
                }
                else{
                    $sessionStorage.previousStateName = 'home';
                    $sessionStorage.previousStateParams = {};
                }
                var $state = $injector.get('$state');
                $state.go('login');
            }
            return $q.reject(response);
        }

        // function getCSRF() {
        //     var doc = $document[0];
        //     if (doc) {
        //         var name = 'CSRF-TOKEN=';
        //         var ca = doc.cookie.split(';');
        //         for (var i = 0; i < ca.length; i++) {
        //             var c = ca[i];
        //             while (c.charAt(0) === ' ') {c = c.substring(1);}
        //
        //             if (c.indexOf(name) !== -1) {
        //                 return c.substring(name.length, c.length);
        //             }
        //         }
        //     }
        //     return '';
        // }
        //
        // function afterCSRFRenewed(oldResponse) {
        //     if (getCSRF() !== '') {
        //         // retry the old request after the new CSRF-TOKEN is obtained
        //         var $http = $injector.get('$http');
        //         return $http(oldResponse.config);
        //     } else {
        //         // unlikely get here but reject with the old response any way and avoid infinite loop
        //         return $q.reject(oldResponse);
        //     }
        // }
    }
})();
