(function() {
    'use strict';

    angular
        .module('vatUtils')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope', '$state', 'ENV', 'VERSION', 'Auth'];

    function NavbarController ($rootScope, $state, ENV, VERSION, Auth) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.inProduction = ENV === 'prod';
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.ENV = ENV;
        vm.VERSION = VERSION;
        // function login () {
        //     collapseNavbar();
        //     LoginService.open();
        // }
        //
        vm.logout = function() {
            collapseNavbar();
            Auth.logout();
            $state.reload();
        };

        function toggleNavbar () {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar () {
            vm.isNavbarCollapsed = true;
        }
    }
})();
