(function() {
    'use strict';

    angular
        .module('vatUtils')
        .controller('LoginController', function () {

        })
        .directive('loginInline', loginInline);

    function loginInline($timeout, $rootScope, $state, Auth, $sessionStorage) {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'app/login-inline/login-inline.html',
            link: function (scope, element, attrs) {

                $timeout(function (){angular.element('#username').focus();});

                scope.formData = {
                    // rememberMe: false
                };

                scope.login = function(event) {
                    event.preventDefault();
                    Auth.login(scope.formData).then(function () {
                        scope.authenticationError = false;

                        $rootScope.$broadcast('authenticationSuccess');

                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is succesful, go to stored previousState and clear previousState
                        if ($sessionStorage.previousStateName && $sessionStorage.previousStateName !== 'login') {
                            var previousStateName = $sessionStorage.previousStateName;
                            var previousStateParams = $sessionStorage.previousStateParams;
                            delete $sessionStorage.previousStateName;
                            delete $sessionStorage.previousStateParams;
                            $state.go(previousStateName, previousStateParams);
                        }
                        else{
                            $state.go('home');
                        }
                    }).catch(function () {
                        scope.authenticationError = true;
                    });
                };
            }
        };
    }
})();
