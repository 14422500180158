(function () {
    "use strict";

    angular.module('vatUtils')
        .factory('FileUploaderLocal', function ($rootScope, AlertService, FileUploader) {

            function create(formDataFn, onComplete) {

                var executeOnComplete = onComplete || angular.noop;

                var uploader = new FileUploader({
                    url: '/api/request-log/process-file',
                    headers: {
                        "X-Requested-With": 'XMLHttpRequest',
                        // "X-CSRF-TOKEN": $cookies.get("CSRF-TOKEN")
                    },
                    queueLimit: 1
                });

                uploader.onWhenAddingFileFailed = function (item /*{File|FileLikeObject}*/, filter, options) {
                    AlertService.error("Nie udało się dodać pliku " + filter.name, "Błąd");
                };

                uploader.onSuccessItem = function (fileItem, response, status, headers) {
                    fileItem.crmfile = response;
                    executeOnComplete(response);
                };

                uploader.onErrorItem = function (fileItem, response, status, headers) {
                    fileItem.isUploaded = false;
                    if (angular.isDefined(response.messages)) {
                        AlertService.error(response.messages[0], "Błąd wysyłania pliku " + fileItem.file.name);
                    }
                    else {
                        AlertService.error("Błąd wysyłania pliku " + fileItem.file.name);
                    }
                };

                uploader.onCancelItem = function (fileItem, response, status, headers) {
                    AlertService.warning("Anulowano wysyłanie pliku " + fileItem.file.name, "Anulowanie");
                };

                uploader.onCompleteAll = function () {
                    if (uploader.getNotUploadedItems().length === 0) {
                        AlertService.success("Przesyłanie zakończone", "Wszystkie pliki wysłane");

                    }
                    uploader.clearQueue();
                    // else {
                    // AlertService.warning("Nie wszystkie pliki zostały poprawnie wysłane na serwer", "UWAGA!", {
                    //     closeButton: true,
                    //     timeOut: 10000
                    // });
                    // }
                    $rootScope.$broadcast('file.saved');
                };
                uploader.onBeforeUploadItem = function (item) {
                    var uploadFormData = {
                        // isLinkActive: true,
                        // isAssignable: false
                    };
                    angular.extend(uploadFormData, formDataFn());
                    item.formData.push(uploadFormData);
                };
                return uploader;
            }

            return {
                create: create
            };
        })
        .service('FilesSimpleUploadService', function ($uibModal) {
            this.open = function (params) {
                return $uibModal.open({
                    templateUrl: 'app/simple-file-upload/simple-file-upload-modal.tpl.html',
                    controller: 'FilesSimpleUploadController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                    }
                });
            };
        })
        .controller('FilesSimpleUploadController', function ($scope, $rootScope, $uibModalInstance, FileUploaderLocal) {
            var close = function (result) {
                $rootScope.$broadcast('requestLog:updated');
                $scope.result = result;
                // $uibModalInstance.close();
            };
            $scope.uploader = FileUploaderLocal.create(formDataFn, close);
            $scope.uploadFormData = {
                type: 'PLI'
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('Canceled');
            };

            function formDataFn() {
                return $scope.uploadFormData;
            }

        });

})();
