(function() {
    'use strict';

    angular
        .module('vatUtils')
        .factory('DateRangePickerConfig', function () {
            var def = {
                ranges: {
                    'Dzis': [ moment(), moment() ],
                    'Wczoraj': [ moment().subtract(1, 'days'), moment().subtract(1, 'days') ],
                    'Ten tydzień': [ moment().startOf('week'), moment().endOf('week') ],
                    'Poprzedni tydzień': [ moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week') ],
                    'Ostatnie 7 dni': [ moment().subtract(6, 'days'), moment() ],
                    'Ostatnie 30 dni': [ moment().subtract(29, 'days'), moment() ],
                    'Ten miesiąc': [ moment().startOf('month'), moment().endOf('month') ],
                    'Poprzedni miesiąc': [ moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month') ],
                    'Ten kwartał': [ moment().startOf('quarter'), moment().endOf('quarter') ],
                    'Poprzedni kwartał': [ moment().subtract(1, "Q").startOf('quarter'), moment().subtract(1, "Q").endOf('quarter') ]
                },
                locale: {
                    applyLabel: 'Zapisz',
                    cancelLabel: 'Wyczyść',
                    fromLabel: 'Od',
                    toLabel: 'Do',
                    customRangeLabel: 'Własny zakres',
                    daysOfWeek: ['Ni', 'Po', 'Wt', 'Śr', 'Cz', 'Pi', 'So'],
                    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
                    firstDay: 1
                },
                format: 'YYYY-MM-DD'
            };

            var service = {
                get: get
            };
            return service;

            function get(override) {
                var result = angular.copy(def);
                angular.extend(result, override);
                return result;
            }

        });

})();
