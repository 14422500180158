(function() {
    'use strict';

    angular
        .module('vatUtils')
        .factory('Auth', Auth);

    Auth.$inject = ['$rootScope', '$state', '$sessionStorage', '$q', 'Principal', 'Account', '$http', 'AuthServerProvider'];

    function Auth ($rootScope, $state, $sessionStorage, $q, Principal, Account, $http, AuthServerProvider) {
        var service = {
            authorize: authorize,
            login: login,
            logout: logout,
        };

        return service;


        function authorize (force) {
            var authReturn = Principal.identity(force).then(authThen);

            return authReturn;

            function authThen () {
                var isAuthenticated = Principal.isAuthenticated();
                // if (isAuthenticated && $rootScope.toState.parent === 'account' && ($rootScope.toState.name === 'login' || $rootScope.toState.name === 'register')) {
                //     $state.go('home');
                // }
                if($rootScope.toState.name === 'login'){
                    return;
                }

                if (isAuthenticated && ($rootScope.fromState && !$rootScope.fromState.name) && $sessionStorage.previousStateName) {
                    var previousStateName = $sessionStorage.previousStateName;
                    var previousStateParams = $sessionStorage.previousStateParams;
                    delete $sessionStorage.previousStateName;
                    delete $sessionStorage.previousStateParams;
                //     if($sessionStorage.previousStateName === 'accessdenied'){
                //         $state.go('home');
                //     }
                //     else{
                        $state.go(previousStateName, previousStateParams);
                //     }
                }


                if ($rootScope.toState && $rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
                    // if (isAuthenticated) {
                    //     $state.go('home');
                    // }
                    // else {
                        $sessionStorage.previousStateName = $rootScope.toState.name;
                        $sessionStorage.previousStateParams = $rootScope.toStateParams;
                        $state.go('login');
                    // }
                }

            }
        }

        // function changePassword (newPassword, callback) {
        //     var cb = callback || angular.noop;
        //
        //     return Password.save(newPassword, function () {
        //         return cb();
        //     }, function (err) {
        //         return cb(err);
        //     }).$promise;
        // }
        //
        // function createAccount (account, callback) {
        //     var cb = callback || angular.noop;
        //
        //     return Register.save(account,
        //         function () {
        //             return cb(account);
        //         },
        //         function (err) {
        //             this.logout();
        //             return cb(err);
        //         }.bind(this)).$promise;
        // }

        function login (credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthServerProvider.login(credentials)
                .then(loginThen)
                .catch(function (err) {
                    this.logout(true);
                    deferred.reject(err);
                    return cb(err);
                }.bind(this));

            function loginThen (data) {
                Principal.identity(true).then(function(account) {
                    // After the login the language will be changed to
                    // the language selected by the user during his registration
                    if (account!== null) {
                        // $translate.use(account.langKey).then(function () {
                        //     $translate.refresh();
                        // });
                    }
                    deferred.resolve(data);
                });
                return cb();
            }

            return deferred.promise;
        }


        function logout (avoidStateReset) {
            AuthServerProvider.logout();
            Principal.authenticate(null);

            if(avoidStateReset){
                return;
            }
            // Reset state memory
            delete $sessionStorage.previousStateName;
            delete $sessionStorage.previousStateParams;
        }

    }
})();
