(function() {
    'use strict';

    var tableLoadingSpinner = {
        template: '<div class="table-loading-spinner" ng-if="!$ctrl.items">' +
        '            <i class="fas fa-spinner fa-spin fa-lg fa-2x"></i>' +
        '        </div>',
        bindings: {
            items: '<'
        }
    };

    angular
        .module('vatUtils')
        .component('tableLoadingSpinner', tableLoadingSpinner);
})();
