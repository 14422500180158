
(function() {
    'use strict';

    angular
        .module('vatUtils')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/?page&size&sort&search&start&end&filtering',
            data: {
                authorities: [ 'ROLE_USER' ]
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                size: {
                    value: '50',
                    squash: true,
                },
                start: {
                    value: moment().startOf('day').toISOString(),
                    squash: true
                },
                end: {
                    value: moment().endOf('day').toISOString(),
                    squash: true
                },
                filtering: {
                    value: "all",
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        size: $stateParams.size,
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        period: {
                            startDate: $stateParams.start,
                            endDate: $stateParams.end
                        },
                        start: PaginationUtil.parseDateTime($stateParams.start),
                        end: PaginationUtil.parseDateTime($stateParams.end),
                        filtering: $stateParams.filtering
                    };
                }]
            }
        });
    }


})();
